import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const Fullscreen = ({ title, background_image, text }) => {
  return (
    <Wrapper fluid={background_image.fluid}>
        <Container>
          <Content>
            <Title>{title.text}</Title>
            <Text>{text.text}</Text>
          </Content>
        </Container>
    </Wrapper>
  )
}

export default Fullscreen

const Wrapper = styled(BackgroundImage)`
  margin: 0px auto;
  padding: 0;
  height:783px;
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items:flex-start;

  @media(max-width: 768px) {
    height:600px;
  }
`

const Container = styled.div`
  width:90%;
  margin:0 auto;
  display:grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px; 

  @media(max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Content = styled.div`
  grid-column:1 / 2;
  background-color:#fff;
  padding:30px;
  top:-30px;
  position:relative;
  border:1px solid rgba(0,0,0,0.05);
`;

const Title = styled.h2`
  font-family: "Moderat Medium", sans-serif;
  color: var(--primary-text);
  font-size: 24px;
  text-align: left;
  align-self: left;
  padding: 0;
  margin-bottom:15px;
`;

const Text = styled.p`
  font-family: "Moderat Regular", sans-serif;
  color: var(--primary-text);
  line-height: 24px;
`